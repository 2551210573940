@charset "UTF-8";

@font-face {
  font-family: "line-icons";
  src:url("../fonts/line-icons.eot");
  src:url("../fonts/line-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/line-icons.woff") format("woff"),
    url("../fonts/line-icons.ttf") format("truetype"),
    url("../fonts/line-icons.svg#line-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "line-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "line-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alerts-01:before {
  content: "a";
}
.icon-alerts-02:before {
  content: "b";
}
.icon-alerts-03:before {
  content: "c";
}
.icon-alerts-04:before {
  content: "d";
}
.icon-alerts-05:before {
  content: "e";
}
.icon-alerts-06:before {
  content: "f";
}
.icon-alerts-07:before {
  content: "g";
}
.icon-alerts-08:before {
  content: "h";
}
.icon-alerts-09:before {
  content: "i";
}
.icon-alerts-10:before {
  content: "j";
}
.icon-alerts-11:before {
  content: "k";
}
.icon-alerts-12:before {
  content: "l";
}
.icon-alerts-13:before {
  content: "m";
}
.icon-arrows-08:before {
  content: "n";
}
.icon-arrows-07:before {
  content: "o";
}
.icon-arrows-06:before {
  content: "p";
}
.icon-arrows-05:before {
  content: "q";
}
.icon-arrows-03:before {
  content: "r";
}
.icon-arrows-04:before {
  content: "s";
}
.icon-arrows-02:before {
  content: "t";
}
.icon-arrows-01:before {
  content: "u";
}
.icon-alerts-18:before {
  content: "v";
}
.icon-alerts-17:before {
  content: "w";
}
.icon-alerts-16:before {
  content: "x";
}
.icon-alerts-15:before {
  content: "y";
}
.icon-alerts-14:before {
  content: "z";
}
.icon-arrows-09:before {
  content: "A";
}
.icon-arrows-10:before {
  content: "B";
}
.icon-arrows-11:before {
  content: "C";
}
.icon-arrows-12:before {
  content: "D";
}
.icon-arrows-13:before {
  content: "E";
}
.icon-arrows-14:before {
  content: "F";
}
.icon-arrows-15:before {
  content: "G";
}
.icon-arrows-16:before {
  content: "H";
}
.icon-arrows-22:before {
  content: "I";
}
.icon-arrows-17:before {
  content: "J";
}
.icon-arrows-18:before {
  content: "K";
}
.icon-arrows-19:before {
  content: "L";
}
.icon-arrows-20:before {
  content: "M";
}
.icon-arrows-21:before {
  content: "N";
}
.icon-arrows-30:before {
  content: "O";
}
.icon-arrows-31:before {
  content: "P";
}
.icon-arrows-32:before {
  content: "Q";
}
.icon-arrows-33:before {
  content: "R";
}
.icon-arrows-34:before {
  content: "S";
}
.icon-arrows-23:before {
  content: "O";
}
.icon-arrows-24:before {
  content: "T";
}
.icon-arrows-25:before {
  content: "U";
}
.icon-arrows-26:before {
  content: "V";
}
.icon-arrows-27:before {
  content: "W";
}
.icon-arrows-28:before {
  content: "X";
}
.icon-arrows-29:before {
  content: "Y";
}
.icon-arrows-36:before {
  content: "Z";
}
.icon-arrows-37:before {
  content: "0";
}
.icon-arrows-38:before {
  content: "1";
}
.icon-arrows-39:before {
  content: "2";
}
.icon-arrows-40:before {
  content: "3";
}
.icon-arrows-41:before {
  content: "4";
}
.icon-badges-votes-01:before {
  content: "5";
}
.icon-badges-votes-02:before {
  content: "6";
}
.icon-arrows-30:before {
  content: "7";
}
.icon-badges-votes-03:before {
  content: "8";
}
.icon-badges-votes-04:before {
  content: "9";
}
.icon-badges-votes-05:before {
  content: "!";
}
.icon-badges-votes-06:before {
  content: "\"";
}
.icon-arrows-35:before {
  content: "#";
}
.icon-badges-votes-07:before {
  content: "$";
}
.icon-badges-votes-08:before {
  content: "%";
}
.icon-badges-votes-09:before {
  content: "&";
}
.icon-badges-votes-10:before {
  content: "'";
}
.icon-badges-votes-11:before {
  content: "(";
}
.icon-badges-votes-12:before {
  content: ")";
}
.icon-badges-votes-13:before {
  content: "*";
}
.icon-badges-votes-14:before {
  content: "+";
}
.icon-badges-votes-15:before {
  content: ",";
}
.icon-badges-votes-16:before {
  content: "-";
}
.icon-chat-messages-01:before {
  content: ".";
}
.icon-chat-messages-02:before {
  content: "/";
}
.icon-chat-messages-03:before {
  content: ":";
}
.icon-chat-messages-04:before {
  content: ";";
}
.icon-chat-messages-05:before {
  content: "<";
}
.icon-chat-messages-06:before {
  content: "=";
}
.icon-chat-messages-07:before {
  content: ">";
}
.icon-chat-messages-08:before {
  content: "?";
}
.icon-chat-messages-09:before {
  content: "@";
}
.icon-chat-messages-10:before {
  content: "[";
}
.icon-chat-messages-11:before {
  content: "]";
}
.icon-chat-messages-12:before {
  content: "^";
}
.icon-chat-messages-13:before {
  content: "_";
}
.icon-chat-messages-14:before {
  content: "`";
}
.icon-chat-messages-15:before {
  content: "{";
}
.icon-chat-messages-16:before {
  content: "|";
}
.icon-documents-bookmarks-01:before {
  content: "}";
}
.icon-documents-bookmarks-02:before {
  content: "~";
}
.icon-documents-bookmarks-03:before {
  content: "\\";
}
.icon-documents-bookmarks-04:before {
  content: "\e000";
}
.icon-documents-bookmarks-05:before {
  content: "\e001";
}
.icon-documents-bookmarks-06:before {
  content: "\e002";
}
.icon-documents-bookmarks-07:before {
  content: "\e003";
}
.icon-documents-bookmarks-08:before {
  content: "\e004";
}
.icon-documents-bookmarks-09:before {
  content: "\e005";
}
.icon-documents-bookmarks-10:before {
  content: "\e006";
}
.icon-documents-bookmarks-11:before {
  content: "\e007";
}
.icon-documents-bookmarks-12:before {
  content: "\e008";
}
.icon-documents-bookmarks-13:before {
  content: "\e009";
}
.icon-documents-bookmarks-14:before {
  content: "\e00a";
}
.icon-documents-bookmarks-15:before {
  content: "\e00b";
}
.icon-documents-bookmarks-16:before {
  content: "\e00c";
}
.icon-ecology-01:before {
  content: "\e00d";
}
.icon-ecology-02:before {
  content: "\e00e";
}
.icon-ecology-03:before {
  content: "\e00f";
}
.icon-ecology-04:before {
  content: "\e010";
}
.icon-ecology-05:before {
  content: "\e011";
}
.icon-ecology-06:before {
  content: "\e012";
}
.icon-ecology-07:before {
  content: "\e013";
}
.icon-ecology-08:before {
  content: "\e014";
}
.icon-ecology-09:before {
  content: "\e015";
}
.icon-ecology-10:before {
  content: "\e016";
}
.icon-ecology-11:before {
  content: "\e017";
}
.icon-ecology-12:before {
  content: "\e018";
}
.icon-ecology-13:before {
  content: "\e019";
}
.icon-ecology-14:before {
  content: "\e01a";
}
.icon-ecology-15:before {
  content: "\e01b";
}
.icon-ecology-16:before {
  content: "\e01c";
}
.icon-education-science-01:before {
  content: "\e01d";
}
.icon-education-science-02:before {
  content: "\e01e";
}
.icon-education-science-03:before {
  content: "\e01f";
}
.icon-education-science-04:before {
  content: "\e020";
}
.icon-education-science-05:before {
  content: "\e021";
}
.icon-education-science-06:before {
  content: "\e022";
}
.icon-education-science-07:before {
  content: "\e023";
}
.icon-education-science-08:before {
  content: "\e024";
}
.icon-education-science-09:before {
  content: "\e025";
}
.icon-education-science-10:before {
  content: "\e026";
}
.icon-education-science-11:before {
  content: "\e027";
}
.icon-education-science-12:before {
  content: "\e028";
}
.icon-education-science-13:before {
  content: "\e029";
}
.icon-education-science-14:before {
  content: "\e02a";
}
.icon-education-science-15:before {
  content: "\e02b";
}
.icon-education-science-16:before {
  content: "\e02c";
}
.icon-education-science-17:before {
  content: "\e02d";
}
.icon-education-science-18:before {
  content: "\e02e";
}
.icon-education-science-19:before {
  content: "\e02f";
}
.icon-education-science-20:before {
  content: "\e030";
}
.icon-emoticons-01:before {
  content: "\e031";
}
.icon-emoticons-02:before {
  content: "\e032";
}
.icon-emoticons-03:before {
  content: "\e033";
}
.icon-emoticons-04:before {
  content: "\e034";
}
.icon-emoticons-05:before {
  content: "\e035";
}
.icon-emoticons-06:before {
  content: "\e036";
}
.icon-emoticons-07:before {
  content: "\e037";
}
.icon-emoticons-08:before {
  content: "\e038";
}
.icon-emoticons-09:before {
  content: "\e039";
}
.icon-emoticons-10:before {
  content: "\e03a";
}
.icon-emoticons-11:before {
  content: "\e03b";
}
.icon-emoticons-12:before {
  content: "\e03c";
}
.icon-emoticons-13:before {
  content: "\e03d";
}
.icon-emoticons-14:before {
  content: "\e03e";
}
.icon-emoticons-15:before {
  content: "\e03f";
}
.icon-emoticons-16:before {
  content: "\e040";
}
.icon-emoticons-17:before {
  content: "\e041";
}
.icon-emoticons-18:before {
  content: "\e042";
}
.icon-emoticons-19:before {
  content: "\e043";
}
.icon-emoticons-20:before {
  content: "\e044";
}
.icon-emoticons-21:before {
  content: "\e045";
}
.icon-emoticons-22:before {
  content: "\e046";
}
.icon-emoticons-23:before {
  content: "\e047";
}
.icon-emoticons-24:before {
  content: "\e048";
}
.icon-emoticons-25:before {
  content: "\e049";
}
.icon-emoticons-26:before {
  content: "\e04a";
}
.icon-emoticons-27:before {
  content: "\e04b";
}
.icon-emoticons-28:before {
  content: "\e04c";
}
.icon-emoticons-29:before {
  content: "\e04d";
}
.icon-emoticons-30:before {
  content: "\e04e";
}
.icon-emoticons-31:before {
  content: "\e04f";
}
.icon-emoticons-32:before {
  content: "\e050";
}
.icon-emoticons-33:before {
  content: "\e051";
}
.icon-emoticons-34:before {
  content: "\e052";
}
.icon-emoticons-35:before {
  content: "\e053";
}
.icon-emoticons-artboard-80:before {
  content: "\e054";
}
.icon-faces-users-01:before {
  content: "\e055";
}
.icon-faces-users-02:before {
  content: "\e056";
}
.icon-faces-users-03:before {
  content: "\e057";
}
.icon-faces-users-04:before {
  content: "\e058";
}
.icon-faces-users-05:before {
  content: "\e059";
}
.icon-faces-users-06:before {
  content: "\e05a";
}
.icon-faces-users-07:before {
  content: "\e05b";
}
.icon-filetypes-01:before {
  content: "\e05c";
}
.icon-filetypes-02:before {
  content: "\e05d";
}
.icon-filetypes-03:before {
  content: "\e05e";
}
.icon-filetypes-04:before {
  content: "\e05f";
}
.icon-filetypes-05:before {
  content: "\e060";
}
.icon-filetypes-06:before {
  content: "\e061";
}
.icon-filetypes-07:before {
  content: "\e062";
}
.icon-filetypes-08:before {
  content: "\e063";
}
.icon-filetypes-09:before {
  content: "\e064";
}
.icon-filetypes-10:before {
  content: "\e065";
}
.icon-filetypes-11:before {
  content: "\e066";
}
.icon-filetypes-12:before {
  content: "\e067";
}
.icon-filetypes-13:before {
  content: "\e068";
}
.icon-filetypes-14:before {
  content: "\e069";
}
.icon-filetypes-15:before {
  content: "\e06a";
}
.icon-filetypes-16:before {
  content: "\e06b";
}
.icon-filetypes-17:before {
  content: "\e06c";
}
.icon-food-01:before {
  content: "\e06d";
}
.icon-food-02:before {
  content: "\e06e";
}
.icon-food-03:before {
  content: "\e06f";
}
.icon-food-04:before {
  content: "\e070";
}
.icon-food-05:before {
  content: "\e071";
}
.icon-food-06:before {
  content: "\e072";
}
.icon-food-07:before {
  content: "\e073";
}
.icon-food-08:before {
  content: "\e074";
}
.icon-food-09:before {
  content: "\e075";
}
.icon-food-10:before {
  content: "\e076";
}
.icon-food-11:before {
  content: "\e077";
}
.icon-food-12:before {
  content: "\e078";
}
.icon-food-13:before {
  content: "\e079";
}
.icon-food-14:before {
  content: "\e07a";
}
.icon-food-15:before {
  content: "\e07b";
}
.icon-food-16:before {
  content: "\e07c";
}
.icon-food-17:before {
  content: "\e07d";
}
.icon-food-18:before {
  content: "\e07e";
}
.icon-graphic-design-01:before {
  content: "\e07f";
}
.icon-graphic-design-02:before {
  content: "\e080";
}
.icon-graphic-design-03:before {
  content: "\e081";
}
.icon-graphic-design-04:before {
  content: "\e082";
}
.icon-graphic-design-05:before {
  content: "\e083";
}
.icon-graphic-design-06:before {
  content: "\e084";
}
.icon-graphic-design-07:before {
  content: "\e085";
}
.icon-graphic-design-08:before {
  content: "\e086";
}
.icon-graphic-design-09:before {
  content: "\e087";
}
.icon-graphic-design-10:before {
  content: "\e088";
}
.icon-graphic-design-11:before {
  content: "\e089";
}
.icon-graphic-design-12:before {
  content: "\e08a";
}
.icon-graphic-design-13:before {
  content: "\e08b";
}
.icon-graphic-design-14:before {
  content: "\e08c";
}
.icon-graphic-design-15:before {
  content: "\e08d";
}
.icon-graphic-design-16:before {
  content: "\e08e";
}
.icon-graphic-design-17:before {
  content: "\e08f";
}
.icon-graphic-design-18:before {
  content: "\e090";
}
.icon-graphic-design-19:before {
  content: "\e091";
}
.icon-graphic-design-20:before {
  content: "\e092";
}
.icon-graphic-design-21:before {
  content: "\e093";
}
.icon-graphic-design-22:before {
  content: "\e094";
}
.icon-graphic-design-23:before {
  content: "\e095";
}
.icon-graphic-design-24:before {
  content: "\e096";
}
.icon-graphic-design-25:before {
  content: "\e097";
}
.icon-graphic-design-26:before {
  content: "\e098";
}
.icon-graphic-design-27:before {
  content: "\e099";
}
.icon-graphic-design-28:before {
  content: "\e09a";
}
.icon-graphic-design-29:before {
  content: "\e09b";
}
.icon-graphic-design-30:before {
  content: "\e09c";
}
.icon-graphic-design-31:before {
  content: "\e09d";
}
.icon-graphic-design-32:before {
  content: "\e09e";
}
.icon-graphic-design-33:before {
  content: "\e09f";
}
.icon-graphic-design-34:before {
  content: "\e0a0";
}
.icon-medical-01:before {
  content: "\e0a1";
}
.icon-medical-02:before {
  content: "\e0a2";
}
.icon-medical-03:before {
  content: "\e0a3";
}
.icon-medical-04:before {
  content: "\e0a4";
}
.icon-medical-05:before {
  content: "\e0a5";
}
.icon-medical-06:before {
  content: "\e0a6";
}
.icon-medical-07:before {
  content: "\e0a7";
}
.icon-medical-08:before {
  content: "\e0a8";
}
.icon-medical-09:before {
  content: "\e0a9";
}
.icon-medical-10:before {
  content: "\e0aa";
}
.icon-medical-11:before {
  content: "\e0ab";
}
.icon-medical-12:before {
  content: "\e0ac";
}
.icon-medical-13:before {
  content: "\e0ad";
}
.icon-medical-14:before {
  content: "\e0ae";
}
.icon-medical-15:before {
  content: "\e0af";
}
.icon-medical-16:before {
  content: "\e0b0";
}
.icon-medical-17:before {
  content: "\e0b1";
}
.icon-medical-18:before {
  content: "\e0b2";
}
.icon-medical-19:before {
  content: "\e0b3";
}
.icon-medical-20:before {
  content: "\e0b4";
}
.icon-medical-21:before {
  content: "\e0b5";
}
.icon-medical-22:before {
  content: "\e0b6";
}
.icon-medical-23:before {
  content: "\e0b7";
}
.icon-medical-24:before {
  content: "\e0b8";
}
.icon-medical-25:before {
  content: "\e0b9";
}
.icon-medical-26:before {
  content: "\e0ba";
}
.icon-medical-27:before {
  content: "\e0bb";
}
.icon-medical-28:before {
  content: "\e0bc";
}
.icon-multimedia-01:before {
  content: "\e0bd";
}
.icon-multimedia-02:before {
  content: "\e0be";
}
.icon-multimedia-03:before {
  content: "\e0bf";
}
.icon-multimedia-04:before {
  content: "\e0c0";
}
.icon-multimedia-05:before {
  content: "\e0c1";
}
.icon-multimedia-06:before {
  content: "\e0c2";
}
.icon-multimedia-07:before {
  content: "\e0c3";
}
.icon-multimedia-08:before {
  content: "\e0c4";
}
.icon-multimedia-09:before {
  content: "\e0c5";
}
.icon-multimedia-10:before {
  content: "\e0c6";
}
.icon-multimedia-11:before {
  content: "\e0c7";
}
.icon-multimedia-12:before {
  content: "\e0c8";
}
.icon-multimedia-13:before {
  content: "\e0c9";
}
.icon-multimedia-14:before {
  content: "\e0ca";
}
.icon-multimedia-15:before {
  content: "\e0cb";
}
.icon-multimedia-16:before {
  content: "\e0cc";
}
.icon-multimedia-17:before {
  content: "\e0cd";
}
.icon-multimedia-18:before {
  content: "\e0ce";
}
.icon-multimedia-19:before {
  content: "\e0cf";
}
.icon-multimedia-20:before {
  content: "\e0d0";
}
.icon-multimedia-21:before {
  content: "\e0d1";
}
.icon-multimedia-22:before {
  content: "\e0d2";
}
.icon-multimedia-23:before {
  content: "\e0d3";
}
.icon-multimedia-24:before {
  content: "\e0d4";
}
.icon-multimedia-25:before {
  content: "\e0d5";
}
.icon-multimedia-26:before {
  content: "\e0d6";
}
.icon-multimedia-27:before {
  content: "\e0d7";
}
.icon-multimedia-28:before {
  content: "\e0d8";
}
.icon-multimedia-29:before {
  content: "\e0d9";
}
.icon-multimedia-30:before {
  content: "\e0da";
}
.icon-multimedia-31:before {
  content: "\e0db";
}
.icon-multimedia-32:before {
  content: "\e0dc";
}
.icon-multimedia-33:before {
  content: "\e0dd";
}
.icon-multimedia-34:before {
  content: "\e0de";
}
.icon-multimedia-35:before {
  content: "\e0df";
}
.icon-multimedia-36:before {
  content: "\e0e0";
}
.icon-multimedia-37:before {
  content: "\e0e1";
}
.icon-multimedia-38:before {
  content: "\e0e2";
}
.icon-multimedia-39:before {
  content: "\e0e3";
}
.icon-multimedia-40:before {
  content: "\e0e4";
}
.icon-nature-01:before {
  content: "\e0e5";
}
.icon-nature-02:before {
  content: "\e0e6";
}
.icon-nature-03:before {
  content: "\e0e7";
}
.icon-nature-04:before {
  content: "\e0e8";
}
.icon-nature-05:before {
  content: "\e0e9";
}
.icon-nature-06:before {
  content: "\e0ea";
}
.icon-nature-07:before {
  content: "\e0eb";
}
.icon-nature-08:before {
  content: "\e0ec";
}
.icon-nature-09:before {
  content: "\e0ed";
}
.icon-nature-10:before {
  content: "\e0ee";
}
.icon-nature-11:before {
  content: "\e0ef";
}
.icon-nature-12:before {
  content: "\e0f0";
}
.icon-nature-13:before {
  content: "\e0f1";
}
.icon-nature-14:before {
  content: "\e0f2";
}
.icon-office-01:before {
  content: "\e0f3";
}
.icon-office-01:before {
  content: "\e0f3";
}
.icon-shopping-15:before {
  content: "\e183";
}
.icon-shopping-16:before {
  content: "\e184";
}
.icon-shopping-17:before {
  content: "\e185";
}
.icon-shopping-18:before {
  content: "\e186";
}
.icon-shopping-19:before {
  content: "\e187";
}
.icon-shopping-20:before {
  content: "\e188";
}
.icon-shopping-21:before {
  content: "\e189";
}
.icon-shopping-22:before {
  content: "\e18a";
}
.icon-shopping-23:before {
  content: "\e18b";
}
.icon-shopping-24:before {
  content: "\e18c";
}
.icon-shopping-25:before {
  content: "\e18d";
}
.icon-shopping-26:before {
  content: "\e18e";
}
.icon-shopping-27:before {
  content: "\e18f";
}
.icon-socialmedia-01:before {
  content: "\e190";
}
.icon-socialmedia-02:before {
  content: "\e191";
}
.icon-socialmedia-03:before {
  content: "\e192";
}
.icon-socialmedia-04:before {
  content: "\e193";
}
.icon-socialmedia-05:before {
  content: "\e194";
}
.icon-socialmedia-06:before {
  content: "\e195";
}
.icon-socialmedia-07:before {
  content: "\e196";
}
.icon-socialmedia-08:before {
  content: "\e197";
}
.icon-socialmedia-09:before {
  content: "\e198";
}
.icon-socialmedia-10:before {
  content: "\e199";
}
.icon-socialmedia-11:before {
  content: "\e19a";
}
.icon-socialmedia-12:before {
  content: "\e19b";
}
.icon-socialmedia-13:before {
  content: "\e19c";
}
.icon-socialmedia-14:before {
  content: "\e19d";
}
.icon-socialmedia-15:before {
  content: "\e19e";
}
.icon-socialmedia-16:before {
  content: "\e19f";
}
.icon-socialmedia-17:before {
  content: "\e1a0";
}
.icon-socialmedia-18:before {
  content: "\e1a1";
}
.icon-socialmedia-19:before {
  content: "\e1a2";
}
.icon-socialmedia-20:before {
  content: "\e1a3";
}
.icon-socialmedia-21:before {
  content: "\e1a4";
}
.icon-socialmedia-22:before {
  content: "\e1a5";
}
.icon-socialmedia-23:before {
  content: "\e1a6";
}
.icon-socialmedia-24:before {
  content: "\e1a7";
}
.icon-socialmedia-25:before {
  content: "\e1a8";
}
.icon-socialmedia-26:before {
  content: "\e1a9";
}
.icon-socialmedia-27:before {
  content: "\e1aa";
}
.icon-socialmedia-28:before {
  content: "\e1ab";
}
.icon-socialmedia-29:before {
  content: "\e1ac";
}
.icon-sport-01:before {
  content: "\e1ad";
}
.icon-sport-02:before {
  content: "\e1ae";
}
.icon-sport-03:before {
  content: "\e1af";
}
.icon-sport-04:before {
  content: "\e1b0";
}
.icon-sport-05:before {
  content: "\e1b1";
}
.icon-sport-06:before {
  content: "\e1b2";
}
.icon-sport-07:before {
  content: "\e1b3";
}
.icon-sport-08:before {
  content: "\e1b4";
}
.icon-sport-09:before {
  content: "\e1b5";
}
.icon-sport-10:before {
  content: "\e1b6";
}
.icon-sport-11:before {
  content: "\e1b7";
}
.icon-sport-12:before {
  content: "\e1b8";
}
.icon-sport-13:before {
  content: "\e1b9";
}
.icon-sport-14:before {
  content: "\e1ba";
}
.icon-sport-15:before {
  content: "\e1bb";
}
.icon-sport-16:before {
  content: "\e1bc";
}
.icon-sport-17:before {
  content: "\e1bd";
}
.icon-sport-18:before {
  content: "\e1be";
}
.icon-text-hierarchy-01:before {
  content: "\e1bf";
}
.icon-text-hierarchy-02:before {
  content: "\e1c0";
}
.icon-text-hierarchy-03:before {
  content: "\e1c1";
}
.icon-text-hierarchy-04:before {
  content: "\e1c2";
}
.icon-text-hierarchy-05:before {
  content: "\e1c3";
}
.icon-text-hierarchy-06:before {
  content: "\e1c4";
}
.icon-text-hierarchy-07:before {
  content: "\e1c5";
}
.icon-text-hierarchy-08:before {
  content: "\e1c6";
}
.icon-text-hierarchy-09:before {
  content: "\e1c7";
}
.icon-text-hierarchy-10:before {
  content: "\e1c8";
}
.icon-touch-gestures-01:before {
  content: "\e1c9";
}
.icon-touch-gestures-02:before {
  content: "\e1ca";
}
.icon-touch-gestures-03:before {
  content: "\e1cb";
}
.icon-touch-gestures-04:before {
  content: "\e1cc";
}
.icon-touch-gestures-05:before {
  content: "\e1cd";
}
.icon-touch-gestures-06:before {
  content: "\e1ce";
}
.icon-touch-gestures-07:before {
  content: "\e1cf";
}
.icon-touch-gestures-08:before {
  content: "\e1d0";
}
.icon-touch-gestures-09:before {
  content: "\e1d1";
}
.icon-touch-gestures-10:before {
  content: "\e1d2";
}
.icon-touch-gestures-11:before {
  content: "\e1d3";
}
.icon-touch-gestures-12:before {
  content: "\e1d4";
}
.icon-touch-gestures-13:before {
  content: "\e1d5";
}
.icon-touch-gestures-14:before {
  content: "\e1d6";
}
.icon-touch-gestures-15:before {
  content: "\e1d7";
}
.icon-touch-gestures-16:before {
  content: "\e1d8";
}
.icon-touch-gestures-17:before {
  content: "\e1d9";
}
.icon-touch-gestures-18:before {
  content: "\e1da";
}
.icon-touch-gestures-19:before {
  content: "\e1db";
}
.icon-touch-gestures-20:before {
  content: "\e1dc";
}
.icon-touch-gestures-21:before {
  content: "\e1dd";
}
.icon-touch-gestures-22:before {
  content: "\e1de";
}
.icon-touch-gestures-23:before {
  content: "\e1df";
}
.icon-touch-gestures-24:before {
  content: "\e1e0";
}
.icon-travel-transportation-01:before {
  content: "\e1e1";
}
.icon-travel-transportation-02:before {
  content: "\e1e2";
}
.icon-travel-transportation-03:before {
  content: "\e1e3";
}
.icon-travel-transportation-04:before {
  content: "\e1e4";
}
.icon-travel-transportation-05:before {
  content: "\e1e5";
}
.icon-travel-transportation-06:before {
  content: "\e1e6";
}
.icon-travel-transportation-07:before {
  content: "\e1e7";
}
.icon-travel-transportation-08:before {
  content: "\e1e8";
}
.icon-travel-transportation-09:before {
  content: "\e1e9";
}
.icon-travel-transportation-10:before {
  content: "\e1ea";
}
.icon-travel-transportation-11:before {
  content: "\e1eb";
}
.icon-travel-transportation-12:before {
  content: "\e1ec";
}
.icon-travel-transportation-13:before {
  content: "\e1ed";
}
.icon-travel-transportation-14:before {
  content: "\e1ee";
}
.icon-travel-transportation-15:before {
  content: "\e1ef";
}
.icon-travel-transportation-16:before {
  content: "\e1f0";
}
.icon-travel-transportation-17:before {
  content: "\e1f1";
}
.icon-travel-transportation-18:before {
  content: "\e1f2";
}
.icon-travel-transportation-19:before {
  content: "\e1f3";
}
.icon-travel-transportation-20:before {
  content: "\e1f4";
}
.icon-weather-01:before {
  content: "\e1f5";
}
.icon-weather-02:before {
  content: "\e1f6";
}
.icon-weather-03:before {
  content: "\e1f7";
}
.icon-weather-04:before {
  content: "\e1f8";
}
.icon-weather-05:before {
  content: "\e1f9";
}
.icon-weather-06:before {
  content: "\e1fa";
}
.icon-weather-07:before {
  content: "\e1fb";
}
.icon-weather-08:before {
  content: "\e1fc";
}
.icon-weather-09:before {
  content: "\e1fd";
}
.icon-weather-10:before {
  content: "\e1fe";
}
.icon-weather-11:before {
  content: "\e1ff";
}
.icon-weather-12:before {
  content: "\e200";
}
.icon-weather-13:before {
  content: "\e201";
}
.icon-weather-14:before {
  content: "\e202";
}
.icon-office-02:before {
  content: "\e0f4";
}
.icon-office-03:before {
  content: "\e0f5";
}
.icon-office-04:before {
  content: "\e0f6";
}
.icon-office-05:before {
  content: "\e0f7";
}
.icon-office-06:before {
  content: "\e0f8";
}
.icon-office-07:before {
  content: "\e0f9";
}
.icon-office-08:before {
  content: "\e0fa";
}
.icon-office-09:before {
  content: "\e0fb";
}
.icon-office-10:before {
  content: "\e0fc";
}
.icon-office-11:before {
  content: "\e0fd";
}
.icon-office-12:before {
  content: "\e0fe";
}
.icon-office-13:before {
  content: "\e0ff";
}
.icon-office-14:before {
  content: "\e100";
}
.icon-office-15:before {
  content: "\e101";
}
.icon-office-16:before {
  content: "\e102";
}
.icon-office-17:before {
  content: "\e103";
}
.icon-office-18:before {
  content: "\e104";
}
.icon-office-19:before {
  content: "\e105";
}
.icon-office-20:before {
  content: "\e106";
}
.icon-office-21:before {
  content: "\e107";
}
.icon-office-22:before {
  content: "\e108";
}
.icon-office-23:before {
  content: "\e109";
}
.icon-office-24:before {
  content: "\e10a";
}
.icon-office-25:before {
  content: "\e10b";
}
.icon-office-26:before {
  content: "\e10c";
}
.icon-office-27:before {
  content: "\e10d";
}
.icon-office-28:before {
  content: "\e10e";
}
.icon-office-29:before {
  content: "\e10f";
}
.icon-office-30:before {
  content: "\e110";
}
.icon-office-31:before {
  content: "\e111";
}
.icon-office-32:before {
  content: "\e112";
}
.icon-office-33:before {
  content: "\e113";
}
.icon-office-34:before {
  content: "\e114";
}
.icon-office-35:before {
  content: "\e115";
}
.icon-office-36:before {
  content: "\e116";
}
.icon-office-37:before {
  content: "\e117";
}
.icon-office-38:before {
  content: "\e118";
}
.icon-office-39:before {
  content: "\e119";
}
.icon-office-40:before {
  content: "\e11a";
}
.icon-office-41:before {
  content: "\e11b";
}
.icon-office-42:before {
  content: "\e11c";
}
.icon-office-43:before {
  content: "\e11d";
}
.icon-office-44:before {
  content: "\e11e";
}
.icon-office-45:before {
  content: "\e11f";
}
.icon-office-46:before {
  content: "\e120";
}
.icon-office-47:before {
  content: "\e121";
}
.icon-office-48:before {
  content: "\e122";
}
.icon-office-49:before {
  content: "\e123";
}
.icon-office-50:before {
  content: "\e124";
}
.icon-office-51:before {
  content: "\e125";
}
.icon-office-52:before {
  content: "\e126";
}
.icon-office-53:before {
  content: "\e127";
}
.icon-office-54:before {
  content: "\e128";
}
.icon-office-55:before {
  content: "\e129";
}
.icon-office-56:before {
  content: "\e12a";
}
.icon-office-57:before {
  content: "\e12b";
}
.icon-office-58:before {
  content: "\e12c";
}
.icon-office-59:before {
  content: "\e12d";
}
.icon-office-60:before {
  content: "\e12e";
}
.icon-office-61:before {
  content: "\e12f";
}
.icon-party-01:before {
  content: "\e130";
}
.icon-party-02:before {
  content: "\e131";
}
.icon-party-03:before {
  content: "\e132";
}
.icon-party-04:before {
  content: "\e133";
}
.icon-party-05:before {
  content: "\e134";
}
.icon-party-06:before {
  content: "\e135";
}
.icon-party-07:before {
  content: "\e136";
}
.icon-party-08:before {
  content: "\e137";
}
.icon-party-09:before {
  content: "\e138";
}
.icon-party-10:before {
  content: "\e139";
}
.icon-party-11:before {
  content: "\e13a";
}
.icon-realestate-living-01:before {
  content: "\e13b";
}
.icon-realestate-living-02:before {
  content: "\e13c";
}
.icon-realestate-living-03:before {
  content: "\e13d";
}
.icon-realestate-living-04:before {
  content: "\e13e";
}
.icon-realestate-living-05:before {
  content: "\e13f";
}
.icon-realestate-living-06:before {
  content: "\e140";
}
.icon-realestate-living-07:before {
  content: "\e141";
}
.icon-realestate-living-08:before {
  content: "\e142";
}
.icon-realestate-living-09:before {
  content: "\e143";
}
.icon-realestate-living-10:before {
  content: "\e144";
}
.icon-realestate-living-11:before {
  content: "\e145";
}
.icon-realestate-living-12:before {
  content: "\e146";
}
.icon-realestate-living-13:before {
  content: "\e147";
}
.icon-realestate-living-14:before {
  content: "\e148";
}
.icon-realestate-living-15:before {
  content: "\e149";
}
.icon-realestate-living-16:before {
  content: "\e14a";
}
.icon-seo-icons-01:before {
  content: "\e14b";
}
.icon-seo-icons-02:before {
  content: "\e14c";
}
.icon-seo-icons-03:before {
  content: "\e14d";
}
.icon-seo-icons-04:before {
  content: "\e14e";
}
.icon-seo-icons-05:before {
  content: "\e14f";
}
.icon-seo-icons-06:before {
  content: "\e150";
}
.icon-seo-icons-07:before {
  content: "\e151";
}
.icon-seo-icons-08:before {
  content: "\e152";
}
.icon-seo-icons-09:before {
  content: "\e153";
}
.icon-seo-icons-10:before {
  content: "\e154";
}
.icon-seo-icons-11:before {
  content: "\e155";
}
.icon-seo-icons-12:before {
  content: "\e156";
}
.icon-seo-icons-13:before {
  content: "\e157";
}
.icon-seo-icons-14:before {
  content: "\e158";
}
.icon-seo-icons-15:before {
  content: "\e159";
}
.icon-seo-icons-16:before {
  content: "\e15a";
}
.icon-seo-icons-17:before {
  content: "\e15b";
}
.icon-seo-icons-18:before {
  content: "\e15c";
}
.icon-seo-icons-19:before {
  content: "\e15d";
}
.icon-seo-icons-20:before {
  content: "\e15e";
}
.icon-seo-icons-21:before {
  content: "\e15f";
}
.icon-seo-icons-22:before {
  content: "\e160";
}
.icon-seo-icons-23:before {
  content: "\e161";
}
.icon-seo-icons-24:before {
  content: "\e162";
}
.icon-seo-icons-25:before {
  content: "\e163";
}
.icon-seo-icons-26:before {
  content: "\e164";
}
.icon-seo-icons-27:before {
  content: "\e165";
}
.icon-seo-icons-28:before {
  content: "\e166";
}
.icon-seo-icons-29:before {
  content: "\e167";
}
.icon-seo-icons-30:before {
  content: "\e168";
}
.icon-seo-icons-31:before {
  content: "\e169";
}
.icon-seo-icons-32:before {
  content: "\e16a";
}
.icon-seo-icons-33:before {
  content: "\e16b";
}
.icon-seo-icons-34:before {
  content: "\e16c";
}
.icon-seo-icons-35:before {
  content: "\e16d";
}
.icon-seo-icons-36:before {
  content: "\e16e";
}
.icon-seo-icons-37:before {
  content: "\e16f";
}
.icon-seo-icons-38:before {
  content: "\e170";
}
.icon-seo-icons-39:before {
  content: "\e171";
}
.icon-seo-icons-40:before {
  content: "\e172";
}
.icon-seo-icons-41:before {
  content: "\e173";
}
.icon-seo-icons-42:before {
  content: "\e174";
}
.icon-shopping-01:before {
  content: "\e175";
}
.icon-shopping-02:before {
  content: "\e176";
}
.icon-shopping-03:before {
  content: "\e177";
}
.icon-shopping-04:before {
  content: "\e178";
}
.icon-shopping-05:before {
  content: "\e179";
}
.icon-shopping-06:before {
  content: "\e17a";
}
.icon-shopping-07:before {
  content: "\e17b";
}
.icon-shopping-08:before {
  content: "\e17c";
}
.icon-shopping-09:before {
  content: "\e17d";
}
.icon-shopping-10:before {
  content: "\e17e";
}
.icon-shopping-11:before {
  content: "\e17f";
}
.icon-shopping-12:before {
  content: "\e180";
}
.icon-shopping-13:before {
  content: "\e181";
}
.icon-shopping-14:before {
  content: "\e182";
}
