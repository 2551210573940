@media print {

    @page {                
        size: A4;
        margin: 0mm;
    }

    html, body {
        width: 1200px;
    }

    body {
        margin: 0 auto;
        line-height: 1em;
        word-spacing: 1px;
        letter-spacing: 0.2px;
        font-size: 14px;
        background:white;
        color:black;
        width: 100%;
        float: none;
    }

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    /* Improve colour contrast of links */
    a:link, a:visited {
        color: #781351
    }

    /* URL */
    a:link, a:visited {
        background: transparent;
        color:#333;
        text-decoration:none;
    }

    a[href]:after {
        content: "" !important;
    }

    a[href^="http://"] {
        color:#000;
    }

    .header {
        display: none;
    }
    
    body > .container {
        padding-top: 0;
    }
}