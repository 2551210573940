/* ----------------------------------------------------------------------------
    Animations
---------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom animations
---------------------------------------------------------------------------- */
@-moz-document url-prefix() {
  .animated,
  .animated * {
    outline: 1px solid transparent;
  }
}
.animated {
  opacity: 0;
}
.animated.roll3dInTop {
  -webkit-animation: roll3dInTop 0.7s 0s 1;
  -moz-animation: roll3dInTop 0.7s 0s 1;
  -o-animation: roll3dInTop 0.7s 0s 1;
  animation: roll3dInTop 0.7s 0s 1;
}
.animated.roll3dInRight {
  -webkit-animation: roll3dInRight 0.7s 0s 1;
  -moz-animation: roll3dInRight 0.7s 0s 1;
  -o-animation: roll3dInRight 0.7s 0s 1;
  animation: roll3dInRight 0.7s 0s 1;
}
.animated.roll3dInLeft {
  -webkit-animation: roll3dInLeft 0.7s 0s 1;
  -moz-animation: roll3dInLeft 0.7s 0s 1;
  -o-animation: roll3dInLeft 0.7s 0s 1;
  animation: roll3dInLeft 0.7s 0s 1;
}
.animated.flip3dHorizontal {
  -webkit-animation: flip3dHorizontal 0.7s 0s 1;
  -moz-animation: flip3dHorizontal 0.7s 0s 1;
  -o-animation: flip3dHorizontal 0.7s 0s 1;
  animation: flip3dHorizontal 0.7s 0s 1;
}
.animated.flip3dVertical {
  -webkit-animation: flip3dVertical 0.7s 0s 1;
  -moz-animation: flip3dVertical 0.7s 0s 1;
  -o-animation: flip3dVertical 0.7s 0s 1;
  animation: flip3dVertical 0.7s 0s 1;
}
.animated.flip3dInTop {
  -webkit-animation: flip3dInTop 0.7s 0s 1;
  -moz-animation: flip3dInTop 0.7s 0s 1;
  -o-animation: flip3dInTop 0.7s 0s 1;
  animation: flip3dInTop 0.7s 0s 1;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.animated.flip3dInBottom {
  -webkit-animation: flip3dInBottom 0.7s 0s 1;
  -moz-animation: flip3dInBottom 0.7s 0s 1;
  -o-animation: flip3dInBottom 0.7s 0s 1;
  animation: flip3dInBottom 0.7s 0s 1;
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  -o-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.animated.flip3dInLeft {
  -webkit-animation: flip3dInLeft 0.7s 0s 1;
  -moz-animation: flip3dInLeft 0.7s 0s 1;
  -o-animation: flip3dInLeft 0.7s 0s 1;
  animation: flip3dInLeft 0.7s 0s 1;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.animated.flip3dInright {
  -webkit-animation: flip3dInright 0.7s 0s 1;
  -moz-animation: flip3dInright 0.7s 0s 1;
  -o-animation: flip3dInright 0.7s 0s 1;
  animation: flip3dInright 0.7s 0s 1;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.animated.bounce,
.animated.flash,
.animated.pulse,
.animated.rubberBand,
.animated.shake,
.animated.swing,
.animated.tada,
.animated.wobble,
.animated.jello,
.animated.bounceIn,
.animated.bounceInDown,
.animated.bounceInLeft,
.animated.bounceInRight,
.animated.bounceInUp,
.animated.bounceOut,
.animated.bounceOutDown,
.animated.bounceOutLeft,
.animated.bounceOutRight,
.animated.bounceOutUp,
.animated.fadeIn,
.animated.fadeInDown,
.animated.fadeInDownBig,
.animated.fadeInLeft,
.animated.fadeInLeftBig,
.animated.fadeInRight,
.animated.fadeInRightBig,
.animated.fadeInUp,
.animated.fadeInUpBig,
.animated.fadeOut,
.animated.fadeOutDown,
.animated.fadeOutDownBig,
.animated.fadeOutLeft,
.animated.fadeOutLeftBig,
.animated.fadeOutRight,
.animated.fadeOutRightBig,
.animated.fadeOutUp,
.animated.fadeOutUpBig,
.animated.flipInX,
.animated.flipInY,
.animated.flipOutX,
.animated.flipOutY,
.animated.lightSpeedIn,
.animated.lightSpeedOut,
.animated.rotateIn,
.animated.rotateInDownLeft,
.animated.rotateInDownRight,
.animated.rotateInUpLeft,
.animated.rotateInUpRight,
.animated.rotateOut,
.animated.rotateOutDownLeft,
.animated.rotateOutDownRight,
.animated.rotateOutUpLeft,
.animated.rotateOutUpRight,
.animated.hinge,
.animated.rollIn,
.animated.rollOut,
.animated.zoomIn,
.animated.zoomInDown,
.animated.zoomInLeft,
.animated.zoomInRight,
.animated.zoomInUp,
.animated.zoomOut,
.animated.zoomOutDown,
.animated.zoomOutLeft,
.animated.zoomOutRight,
.animated.zoomOutUp,
.animated.slideInDown,
.animated.slideInLeft,
.animated.slideInRight,
.animated.slideInUp,
.animated.slideOutDown,
.animated.slideOutLeft,
.animated.slideOutRight,
.animated.slideOutUp,
.animated.roll3dInTop,
.animated.roll3dInLeft,
.animated.roll3dInRight,
.animated.flip3dHorizontal,
.animated.flip3dVertical,
.animated.flip3dInTop,
.animated.flip3dInBottom,
.animated.flip3dInLeft,
.animated.flip3dInRight {
  opacity: 1;
}
.animated.fadeInDown,
.animated.fadeInDownBig,
.animated.fadeInLeft,
.animated.fadeInLeftBig,
.animated.fadeInRight,
.animated.fadeInRightBig,
.animated.fadeInUp,
.animated.fadeInUpBig,
.animated.fadeOutDown,
.animated.fadeOutDownBig,
.animated.fadeOutLeft,
.animated.fadeOutLeftBig,
.animated.fadeOutRight,
.animated.fadeOutRightBig,
.animated.fadeOutUp,
.animated.fadeOutUpBig,
.animated.rotateIn,
.animated.rotateInDownLeft,
.animated.rotateInDownRight,
.animated.rotateInUpLeft,
.animated.rotateInUpRight,
.animated.rotateOut,
.animated.rotateOutDownLeft,
.animated.rotateOutDownRight,
.animated.rotateOutUpLeft,
.animated.rotateOutUpRight,
.animated.zoomIn,
.animated.zoomInDown,
.animated.zoomInLeft,
.animated.zoomInRight,
.animated.zoomInUp,
.animated.zoomOut,
.animated.zoomOutDown,
.animated.zoomOutLeft,
.animated.zoomOutRight,
.animated.zoomOutUp,
.animated.slideInDown,
.animated.slideInLeft,
.animated.slideInRight,
.animated.slideInUp,
.animated.slideOutDown,
.animated.slideOutLeft,
.animated.slideOutRight,
.animated.slideOutUp,
.animated.roll3dInTop,
.animated.roll3dInLeft,
.animated.roll3dInRight,
.animated.flip3dHorizontal,
.animated.flip3dVertical,
.animated.flip3dInTop,
.animated.flip3dInBottom,
.animated.flip3dInLeft,
.animated.flip3dInRight {
  -webkit-animation-timing-function: cubic-bezier(0.51, 0.02, 0, 1.335);
  -moz-animation-timing-function: cubic-bezier(0.51, 0.02, 0, 1.335);
  -o-animation-timing-function: cubic-bezier(0.51, 0.02, 0, 1.335);
  animation-timing-function: cubic-bezier(0.51, 0.02, 0, 1.335);
}
.animated.roll3dInTop,
.animated.roll3dInLeft,
.animated.roll3dInRight,
.animated.flip3dHorizontal,
.animated.flip3dVertical,
.animated.flip3dInTop,
.animated.flip3dInBottom,
.animated.flip3dInLeft,
.animated.flip3dInRight {
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
/* Animations keyframes */
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes roll3dInTop{ 0% { opacity: 0; -webkit-transform: perspective(200px) rotateX(-50deg) translate3d(0, 600px, -20px) } 100% { opacity: 1; -webkit-transform: perspective(200px) rotateX(0) translate3d(0, 0, 0) }}
@-moz-keyframes roll3dInTop{ 0% { opacity: 0; -moz-transform: perspective(200px) rotateX(-50deg) translate3d(0, 600px, -20px) } 100% { opacity: 1; -moz-transform: perspective(200px) rotateX(0) translate3d(0, 0, 0) }}
@-o-keyframes roll3dInTop{ 0% { opacity: 0; -o-transform: perspective(200px) rotateX(-50deg) translate3d(0, 600px, -20px) } 100% { opacity: 1; -o-transform: perspective(200px) rotateX(0) translate3d(0, 0, 0) }}
@keyframes roll3dInTop{ 0% { opacity: 0;-webkit-transform: perspective(200px) rotateX(-50deg) translate3d(0, 600px, -20px);-moz-transform: perspective(200px) rotateX(-50deg) translate3d(0, 600px, -20px);-ms-transform: perspective(200px) rotateX(-50deg) translate3d(0, 600px, -20px);transform: perspective(200px) rotateX(-50deg) translate3d(0, 600px, -20px);} 100% { opacity: 1;-webkit-transform: perspective(200px) rotateX(0) translate3d(0, 0, 0);-moz-transform: perspective(200px) rotateX(0) translate3d(0, 0, 0);-ms-transform: perspective(200px) rotateX(0) translate3d(0, 0, 0);transform: perspective(200px) rotateX(0) translate3d(0, 0, 0);};
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes roll3dInLeft{ 0% { opacity: 0; -webkit-transform: perspective(200px) rotateY(80deg) translate3d(600px, 0, -20px); } 100% { opacity: 1; -webkit-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0); }}
@-moz-keyframes roll3dInLeft{ 0% { opacity: 0; -moz-transform: perspective(200px) rotateY(80deg) translate3d(600px, 0, -20px); } 100% { opacity: 1; -moz-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0); }}
@-o-keyframes roll3dInLeft{ 0% { opacity: 0; -o-transform: perspective(200px) rotateY(80deg) translate3d(600px, 0, -20px); } 100% { opacity: 1; -o-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0); }}
@keyframes roll3dInLeft{ 0% { opacity: 0;-webkit-transform: perspective(200px) rotateY(80deg) translate3d(600px, 0, -20px);-moz-transform: perspective(200px) rotateY(80deg) translate3d(600px, 0, -20px);-ms-transform: perspective(200px) rotateY(80deg) translate3d(600px, 0, -20px);transform: perspective(200px) rotateY(80deg) translate3d(600px, 0, -20px); } 100% { opacity: 1;-webkit-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0);-moz-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0);-ms-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0);transform: perspective(200px) rotateY(0) translate3d(0, 0, 0); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes roll3dInRight{ 0% { opacity: 0; -webkit-transform: perspective(200px) rotateY(-50deg) translate3d(-600px, 0, -20px) } 100% { opacity: 1; -webkit-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0) }}
@-moz-keyframes roll3dInRight{ 0% { opacity: 0; -moz-transform: perspective(200px) rotateY(-50deg) translate3d(-600px, 0, -20px) } 100% { opacity: 1; -moz-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0) }}
@-o-keyframes roll3dInRight{ 0% { opacity: 0; -o-transform: perspective(200px) rotateY(-50deg) translate3d(-600px, 0, -20px) } 100% { opacity: 1; -o-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0) }}
@keyframes roll3dInRight{ 0% { opacity: 0;-webkit-transform: perspective(200px) rotateY(-50deg) translate3d(-600px, 0, -20px);-moz-transform: perspective(200px) rotateY(-50deg) translate3d(-600px, 0, -20px);-ms-transform: perspective(200px) rotateY(-50deg) translate3d(-600px, 0, -20px);transform: perspective(200px) rotateY(-50deg) translate3d(-600px, 0, -20px);} 100% { opacity: 1;-webkit-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0);-moz-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0);-ms-transform: perspective(200px) rotateY(0) translate3d(0, 0, 0);transform: perspective(200px) rotateY(0) translate3d(0, 0, 0);};
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes flip3dInTop{ 0% { opacity: 0; -webkit-transform: perspective(1300px) rotateX(90deg); } 100% { opacity: 1; -webkit-transform: perspective(1300px) rotateX(0); }}
@-moz-keyframes flip3dInTop{ 0% { opacity: 0; -moz-transform: perspective(1300px) rotateX(90deg); } 100% { opacity: 1; -moz-transform: perspective(1300px) rotateX(0); }}
@-o-keyframes flip3dInTop{ 0% { opacity: 0; -o-transform: perspective(1300px) rotateX(90deg); } 100% { opacity: 1; -o-transform: perspective(1300px) rotateX(0); }}
@keyframes flip3dInTop{ 0% { opacity: 0;-webkit-transform: perspective(1300px) rotateX(90deg);-moz-transform: perspective(1300px) rotateX(90deg);-ms-transform: perspective(1300px) rotateX(90deg);transform: perspective(1300px) rotateX(90deg); } 100% { opacity: 1;-webkit-transform: perspective(1300px) rotateX(0);-moz-transform: perspective(1300px) rotateX(0);-ms-transform: perspective(1300px) rotateX(0);transform: perspective(1300px) rotateX(0); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes flip3dInBottom{ 0% { opacity: 0; -webkit-transform: perspective(1300px) rotateX(-90deg); } 100% { opacity: 1; -webkit-transform: perspective(1300px) rotateX(0); }}
@-moz-keyframes flip3dInBottom{ 0% { opacity: 0; -moz-transform: perspective(1300px) rotateX(-90deg); } 100% { opacity: 1; -moz-transform: perspective(1300px) rotateX(0); }}
@-o-keyframes flip3dInBottom{ 0% { opacity: 0; -o-transform: perspective(1300px) rotateX(-90deg); } 100% { opacity: 1; -o-transform: perspective(1300px) rotateX(0); }}
@keyframes flip3dInBottom{ 0% { opacity: 0;-webkit-transform: perspective(1300px) rotateX(-90deg);-moz-transform: perspective(1300px) rotateX(-90deg);-ms-transform: perspective(1300px) rotateX(-90deg);transform: perspective(1300px) rotateX(-90deg); } 100% { opacity: 1;-webkit-transform: perspective(1300px) rotateX(0);-moz-transform: perspective(1300px) rotateX(0);-ms-transform: perspective(1300px) rotateX(0);transform: perspective(1300px) rotateX(0); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes flip3dInLeft{ 0% { opacity: 0; -webkit-transform: perspective(1300px) rotateY(90deg); } 100% { opacity: 1; -webkit-transform: perspective(1300px) rotateY(0); }}
@-moz-keyframes flip3dInLeft{ 0% { opacity: 0; -moz-transform: perspective(1300px) rotateY(90deg); } 100% { opacity: 1; -moz-transform: perspective(1300px) rotateY(0); }}
@-o-keyframes flip3dInLeft{ 0% { opacity: 0; -o-transform: perspective(1300px) rotateY(90deg); } 100% { opacity: 1; -o-transform: perspective(1300px) rotateY(0); }}
@keyframes flip3dInLeft{ 0% { opacity: 0;-webkit-transform: perspective(1300px) rotateY(90deg);-moz-transform: perspective(1300px) rotateY(90deg);-ms-transform: perspective(1300px) rotateY(90deg);transform: perspective(1300px) rotateY(90deg); } 100% { opacity: 1;-webkit-transform: perspective(1300px) rotateY(0);-moz-transform: perspective(1300px) rotateY(0);-ms-transform: perspective(1300px) rotateY(0);transform: perspective(1300px) rotateY(0); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes flip3dInRight{ 0% { opacity: 0; -webkit-transform: perspective(1300px) rotateY(-90deg); } 100% { opacity: 1; -webkit-transform: perspective(1300px) rotateY(0); }}
@-moz-keyframes flip3dInRight{ 0% { opacity: 0; -moz-transform: perspective(1300px) rotateY(-90deg); } 100% { opacity: 1; -moz-transform: perspective(1300px) rotateY(0); }}
@-o-keyframes flip3dInRight{ 0% { opacity: 0; -o-transform: perspective(1300px) rotateY(-90deg); } 100% { opacity: 1; -o-transform: perspective(1300px) rotateY(0); }}
@keyframes flip3dInRight{ 0% { opacity: 0;-webkit-transform: perspective(1300px) rotateY(-90deg);-moz-transform: perspective(1300px) rotateY(-90deg);-ms-transform: perspective(1300px) rotateY(-90deg);transform: perspective(1300px) rotateY(-90deg); } 100% { opacity: 1;-webkit-transform: perspective(1300px) rotateY(0);-moz-transform: perspective(1300px) rotateY(0);-ms-transform: perspective(1300px) rotateY(0);transform: perspective(1300px) rotateY(0); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes flip3dHorizontal{ 0% { opacity: 0; -webkit-transform: perspective(1300px) rotateY(-90deg); } 50% { opacity: 1; -webkit-transform: perspective(1300px) rotateY(20deg); } 85%{ -webkit-transform: perspective(1300px) rotateY(-10deg); } 100% { opacity: 1; -webkit-transform: perspective(1300px) rotateY(0); }}
@-moz-keyframes flip3dHorizontal{ 0% { opacity: 0; -moz-transform: perspective(1300px) rotateY(-90deg); } 50% { opacity: 1; -moz-transform: perspective(1300px) rotateY(20deg); } 85%{ -moz-transform: perspective(1300px) rotateY(-10deg); } 100% { opacity: 1; -moz-transform: perspective(1300px) rotateY(0); }}
@-o-keyframes flip3dHorizontal{ 0% { opacity: 0; -o-transform: perspective(1300px) rotateY(-90deg); } 50% { opacity: 1; -o-transform: perspective(1300px) rotateY(20deg); } 85%{ -o-transform: perspective(1300px) rotateY(-10deg); } 100% { opacity: 1; -o-transform: perspective(1300px) rotateY(0); }}
@keyframes flip3dHorizontal{ 0% { opacity: 0;-webkit-transform: perspective(1300px) rotateY(-90deg);-moz-transform: perspective(1300px) rotateY(-90deg);-ms-transform: perspective(1300px) rotateY(-90deg);transform: perspective(1300px) rotateY(-90deg); } 50% { opacity: 1;-webkit-transform: perspective(1300px) rotateY(20deg);-moz-transform: perspective(1300px) rotateY(20deg);-ms-transform: perspective(1300px) rotateY(20deg);transform: perspective(1300px) rotateY(20deg); } 85%{-webkit-transform: perspective(1300px) rotateY(-10deg);-moz-transform: perspective(1300px) rotateY(-10deg);-ms-transform: perspective(1300px) rotateY(-10deg);transform: perspective(1300px) rotateY(-10deg); } 100% { opacity: 1;-webkit-transform: perspective(1300px) rotateY(0);-moz-transform: perspective(1300px) rotateY(0);-ms-transform: perspective(1300px) rotateY(0);transform: perspective(1300px) rotateY(0); };
}
lesshat-selector {
  -lh-property: 0; } 
@-webkit-keyframes flip3dVertical{ 0% { opacity: 0; -webkit-transform: perspective(1300px) rotateX(-90deg); } 50% { opacity: 1; -webkit-transform: perspective(1300px) rotateX(20deg); } 85%{ -webkit-transform: perspective(1300px) rotateX(-10deg); } 100% { opacity: 1; -webkit-transform: perspective(1300px) rotateX(0); }}
@-moz-keyframes flip3dVertical{ 0% { opacity: 0; -moz-transform: perspective(1300px) rotateX(-90deg); } 50% { opacity: 1; -moz-transform: perspective(1300px) rotateX(20deg); } 85%{ -moz-transform: perspective(1300px) rotateX(-10deg); } 100% { opacity: 1; -moz-transform: perspective(1300px) rotateX(0); }}
@-o-keyframes flip3dVertical{ 0% { opacity: 0; -o-transform: perspective(1300px) rotateX(-90deg); } 50% { opacity: 1; -o-transform: perspective(1300px) rotateX(20deg); } 85%{ -o-transform: perspective(1300px) rotateX(-10deg); } 100% { opacity: 1; -o-transform: perspective(1300px) rotateX(0); }}
@keyframes flip3dVertical{ 0% { opacity: 0;-webkit-transform: perspective(1300px) rotateX(-90deg);-moz-transform: perspective(1300px) rotateX(-90deg);-ms-transform: perspective(1300px) rotateX(-90deg);transform: perspective(1300px) rotateX(-90deg); } 50% { opacity: 1;-webkit-transform: perspective(1300px) rotateX(20deg);-moz-transform: perspective(1300px) rotateX(20deg);-ms-transform: perspective(1300px) rotateX(20deg);transform: perspective(1300px) rotateX(20deg); } 85%{-webkit-transform: perspective(1300px) rotateX(-10deg);-moz-transform: perspective(1300px) rotateX(-10deg);-ms-transform: perspective(1300px) rotateX(-10deg);transform: perspective(1300px) rotateX(-10deg); } 100% { opacity: 1;-webkit-transform: perspective(1300px) rotateX(0);-moz-transform: perspective(1300px) rotateX(0);-ms-transform: perspective(1300px) rotateX(0);transform: perspective(1300px) rotateX(0); };
}
