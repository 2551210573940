/* ----------------------------------------------------------------------------
    Forms
---------------------------------------------------------------------------- */


.form {
    .clearfix();

    display: block;
    padding: 25px 12px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
    text-align: left;
}

.registration-form {
    margin: 0 auto;
    max-width: 370px;
    background-color: @white;
}

.registration-form-alt {
    position: relative;
    margin: 0 auto;
    padding: 15px 0 0;
    background-color: fade(@black, 15%);
    border-radius: 3px;
}

.newsletter-form {
    margin-left: auto;
    margin-right: auto;

    fieldset {
        text-align: center;
    }
}

.mailchimp-form {
    position: relative;

    .response {
        position: absolute;
        top: 100%;
        left: 15px;
        right: 15px;
        margin-top: 5px;
    }
}

.form-mail-us {
    padding: 0 0 0 40px;

    input[type="submit"].btn {
        margin-top: 30px;
    }
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: @base-color;
    border-color: @base-color;
}

fieldset {
    position: relative;
    text-align: left;
}

label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: @font-size-base;
    font-weight: @normal-weight;

    &.error {
        &:extend(.base-clr-txt);

        position: absolute;
        bottom: 0;
        margin: 0 0 2px;
        display: block;
        width: 100%;
        font-size: @font-size-thin;
        line-height: 1;
    }
}

.error-message {

}

select.form-control, input[type=date].form-control {
    height: 42px;
    font-size: @font-size-base;
    box-shadow: none;
    border: 1px solid #e9e9e9;
}

textarea.form-control {
    font-size: @font-size-base;
}

textarea,
input[type=text],
input[type=url],
input[type=tel],
input[type=number],
input[type=color],
input[type=email],
input[type=email],
input[type=password] {
    .transition(~"color 0.2s ease, background 0.2s ease, border 0.2s ease");
    .box-shadow(none);

    margin: 0 0 15px;
    vertical-align: top;
    display: inline-block;
    width: 100%;
    font-size: @font-size-base;
    color: @text-gray;
    background-color: @white;
    border: 1px solid @gray-lighter;
    border-radius: 2px;
    outline: none;

    &:hover,
    &:focus {
        color: @text-gray;
    }

    &:not(textarea) {
        height: 42px;
        padding: 0 10px;
        line-height: 35px;
    }

    &.error {
        &:extend(.base-clr-bd);
    }
}

textarea {
    padding:  5px 10px;
    resize: none;
    line-height: 20px;
    min-height: 120px;
}

select {
    margin: 0 0 15px;
    display: inline-block;
    width: 100%;
    height: 39px;
    cursor: pointer;
    outline: none;

    option {
        padding: 2px 10px;
    }
}


input[type=checkbox] {
    .transition(~"border 0.15s ease");
    .border-radius(0);
    .appearance(none);

    position: relative;
    margin: 0 15px 0 0;
    vertical-align: text-bottom;
    width: 23px;
    height: 23px;
    border: 1px solid @gray-lighter;
    background-color: @white;
    cursor: pointer;

    &:hover,
    &:focus {
        border-color: darken(@gray-lighter, 5%);
    }

    &:before {
        .transition(~"opacity 0.15s ease");
        .rotate(-45deg);

        position: absolute;
        left: 2px;
        top: 2px;
        width: 15px;
        height: 10px;
        content: "";
        border-style: solid;
        border-width: 0 0 2px 2px;
        border-color: transparent transparent @gray-dark @gray-dark;
        border-radius: 1px;
        opacity: 0;
    }

    &:checked:before {
        opacity: 1;
    }

    & + p,
    & + label {
        display: inline;
    }
}

input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus {
    outline: none;
}

input.btn {
    margin: 0 auto;
}

input.btn.btn-outline {
    &:extend(.base-clr-txt);
}

.pseudo-select {
    .user-select(none);

    position: relative;
    display: inline-block;
    width: 100%;
    font-size: @font-size-small;

    input {
        opacity: 0;
        position: absolute;
        pointer-events: none;

        &:focus + .pseudo-select-field {
            color: @text-gray;
        }
    }

    .pseudo-select-field {
        .transition(~"color 0.2s ease, background 0.2s ease, border 0.2s ease");

        position: relative;
        margin: 0 0 15px;
        vertical-align: top;
        display: inline-block;
        width: 100%;
        height: 39px;
        padding: 0 32px 0 10px;
        font-size: @font-size-small;
        color: @text-alt-color;
        line-height: 35px;
        white-space: nowrap;
        background-color: @white;
        border: 1px solid @gray-lighter;
        border-radius: 2px;
        z-index: 10;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            color: @text-gray;
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: -2px;
            width: 0;
            height: 0;
            content: "";
            border-style: solid;
            border-width: 5px 4px 0 4px;
            border-color: @gray-light transparent transparent transparent;

        }
    }

    .pseudo-select-dropdown {
        .box-shadow(-1px 8px 16px 0 fade(@black, 18%));

        position: absolute;
        top: 38px;
        left: 0;
        display: none;
        min-width: 100%;
        max-width: 100%;
        max-height: 200px;
        background-color: @white;
        border: 1px solid @gray-lighter;
        z-index: 15;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .pseudo-select-dropdown-item {
        .transition(~"color 0.2s ease, background 0.2s ease");

        display: inline-block;
        width: 100%;
        padding: 0 11px;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
            cursor: default;
        }

        &.selected:not(.disabled) {
            &:extend(.base-clr-txt);
        }

        &:hover {
            background-color: fade(@black, 5%);
        }
    }
}

input[type="submit"] + .loading {
    &:extend(.base-clr-txt);

    right: -10px;
    text-indent: -1px;
}

button[type="submit"] .loading {
    margin: -6px 0 0 10px;
    padding: 0;
    text-indent: -2px;
}

.loading {
    position: absolute;
    top: 50%;
    margin: -6px 0 0;
    display: inline-block;
    padding: 0;
    font-size: 18px;
    width: 12px;
    height: 12px;
    line-height: 0.7;
    color: inherit;
    -webkit-animation: spinLoader 1.2s linear infinite;
    -o-animation: spinLoader 1.2s linear infinite;
    animation: spinLoader 1.2s linear infinite;
    -webkit-transition: opacity 0.15s ease;
    -o-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;

    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}

@-webkit-keyframes spinLoader {
    from {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinLoader {
    from {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinLoader {
    from {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinLoader {
    from {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width: @screen-sm-max ) {
    .form-mail-us {
        padding: 0 0 0 20px;
    }
}

@media (max-width: @screen-sm-max ) {
    .form-mail-us {
        padding: 0;
    }
}

@media (max-width: @screen-xs-max ) {
    .form-mail-us {
        padding-top: 40px;

        [class*="col-"] {
            padding: 0;
        }
    }
}
