/* ----------------------------------------------------------------------------
    Sponsors
---------------------------------------------------------------------------- */


.sponsors {
    font-size: 0;
    line-height: 0;
}

.sponsor {
    margin: 15px;
    display: inline-block;

    &:not(.inline) {
        position: relative;
        vertical-align: top;
        border: 2px solid @gray-lighter;

        img {
            .transform(~"translateX(-50%) translateY(-50%)");

            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            max-width: 80%;
            max-height: 80%;
        }
    }

    &:not(.inline):not(.big) {
        width: 160px;
        height: 160px;
    }

    &.big {
        width: 255px;
        height: 255px;
    }

    &.inline {
        vertical-align: middle;
    }
}

@media (max-width: @screen-md-max) {
    .sponsor {
        &:not(.inline):not(.big) {
            margin: 8px;
            width: 140px;
            height: 140px;
        }

        &.big {
            margin: 15px;
            width: 205px;
            height: 205px;
        }
    }
}

@media (max-width: @screen-sm-max) {
    .sponsor {
        &:not(.inline):not(.big) {
            margin: 5px;
            width: 110px;
            height: 110px;
        }

        &.big {
            margin: 10px;
            width: 160px;
            height: 160px;
        }
    }
}

@media (max-width: @screen-xs-max) {
    &.inline {
        margin: 10px;
    }
}

@media (max-width: @screen-xs-min) {
    .sponsor {
        &:not(.inline):not(.big) {
            margin: 5px;
            width: 80px;
            height: 80px;
        }

        &.big {
            margin: 10px;
            width: 115px;
            height: 115px;
        }
    }
}
