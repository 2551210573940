/* ----------------------------------------------------------------------------
    Counters
---------------------------------------------------------------------------- */

.counters-wrapper {
    font-size: 0;

    & > * {
        font-size: @font-size-base;
    }
}

.counter-block {
    margin: 16px;
    vertical-align: top;
    display: inline-block;
    width: 168px;
    height: 168px;
    text-align: center;

    &.counter-block-solid {
        .counter-box {
            &:extend(.base-clr-bg);

            border-width: 0;
            border-color: transparent;
        }

        .counter-content {
            padding: 0 20px;

            * {
                color: @text-white;
            }
        }
    }

    &.counter-block-border {
        .counter-box {
            &:extend(.base-clr-bd);

            background-color: transparent;
        }
    }

    &.counter-block-no-border {
        &.has-separator .counter-content > .fa:after,
        &.has-separator .counter-content > .count:after {
            position: absolute;
            top: 75px;
            right: -2px;
            width: 2px;
            height: 50px;
            display: block;
            content: "";
            background-color: @gray-blue;
        }

        .counter-box {
            background-color: transparent;
            border: none;

            .counter-content {
                vertical-align: middle;
                padding: 0;

                .title {
                    font-family: @font-family-heading;
                    font-size: @font-size-thin;
                    font-weight: @bold-weight;
                    text-transform: uppercase;
                    letter-spacing: 0.6em;
                    line-height: 18px;
                }
            }
        }
    }

    &.counter-block-white {
        .counter-content * {
            color: @text-white;
        }
    }
}

.counter-box {
    display: table;
    width: 100%;
    height: 100%;
    background-color: @white-alt;
    border-style: solid;
    border-width: 2px;
    border-color: @gray-blue;

    .counter-content {
        vertical-align: middle;
        display: table-cell;
        padding: 0 16px;

        & > .count {
            &:extend(.base-clr-txt);

            display: inline-block;
            width: 100%;
            font-family: @font-family-heading;
            font-size: 80px;
            font-weight: @bold-weight;
            line-height: 100px;
        }

        & > .fa {
            width: 100%;
            font-size: 78px;
            line-height: 100px;
        }

        .title {
            &:extend(.text-alt);

            margin: 0;
            font-size: @font-size-small;
            line-height: 16px;
        }
    }
}

@media (max-width: @screen-sm-max) {
    .counter-block {
        margin: 10px;
        width: 150px;
        height: 150px;
    }

    .counter-box .counter-content > .fa,
    .counter-box .counter-content > .count {
        line-height: 90px;
    }

    .counter-block.counter-block-no-border + .counter-block .counter-content > .fa:after,
    .counter-block.counter-block-no-border + .counter-block .counter-content > .count:after {
        left: -12px;
    }
}

@media (max-width: @screen-xs-max) {
    .counter-block {
        margin: 5px;
        width: 114px;
        height: 114px;
    }

    .counter-box .counter-content > .fa,
    .counter-box .counter-content > .count {
        font-size: 50px;
        line-height: 65px;
    }

    .counter-block.counter-block-no-border + .counter-block .counter-content > .fa:after,
    .counter-block.counter-block-no-border + .counter-block .counter-content > .count:after {
        left: -7px;
    }

    .counter-block.counter-block-no-border .counter-box .counter-content .title {
        letter-spacing: 0.3em;
    }
}

@media (max-width: @screen-xs-middle) {
    .counter-block.counter-block-no-border + .counter-block .counter-content > .fa:after,
    .counter-block.counter-block-no-border + .counter-block .counter-content > .count:after {
        display: none;
    }
}
