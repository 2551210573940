//
//  Fonts
// ------------------------------------------------


// Roboto
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,300italic,400italic,500italic&subset=latin,latin-ext);

// PT Sans Caption
@import url(https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700);

// Line Icons
@import (inline)   "vendors/font-lineicons.css";

.btn i.fa-btn {
	margin: 0;
	padding: 0;
}